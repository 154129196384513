import { useState } from "react"
import styled from "styled-components"

import { THome, THomeType } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MSelect, TMSelectOptions } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

type TFormValues = {
  space_use_type: NonNullable<THomeType>
  floor_number: string
  apartment_number: string
}

type EditBuildingUnitDialogProps = TMDialogProps & {
  home?: THome | null
  onSubmit: (values: TFormValues) => void
}

export function EditBuildingHomeDialog({
  open,
  onClose,
  home,
  title,
  description,
  confirmLabel,
  onSubmit,
  loading = false,
  error = null,
}: EditBuildingUnitDialogProps) {
  const { t, langKeys } = useTranslate()
  const [unit, setUnit] = useState<TFormValues>({
    space_use_type: home?.space_use_type ?? "private",
    floor_number: home?.address?.floor_number ?? "",
    apartment_number: home?.address?.apartment_number ?? "",
  })

  return (
    <MDialog
      title={title}
      description={description}
      open={open}
      formId={EDIT_BUILDING_HOME_FORM_ID}
      onClose={() => {
        onClose()
        setUnit({
          space_use_type: home?.space_use_type ?? "private",
          floor_number: home?.address?.floor_number ?? "",
          apartment_number: home?.address?.apartment_number ?? "",
        })
      }}
      confirmLabel={confirmLabel || t(langKeys.ok)}
      loading={loading}
      error={error}
    >
      <Form
        id={EDIT_BUILDING_HOME_FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit({
            space_use_type: unit.space_use_type,
            floor_number: unit.floor_number,
            apartment_number: unit.apartment_number,
          })
        }}
      >
        <MSelect
          label={t(langKeys.home_type)}
          value={unit.space_use_type}
          options={
            [
              {
                label: t(
                  langKeys.home_group_building_create_home_private_area_title
                ),
                value: "private",
              },
              {
                label: t(
                  langKeys.home_group_building_create_home_communal_area_title
                ),
                value: "communal",
              },
            ] satisfies TMSelectOptions<NonNullable<THomeType>>
          }
          onChange={(value: string) => {
            if (value === "private" || value === "communal") {
              setUnit({
                ...unit,
                space_use_type: value,
                apartment_number:
                  value === "private" ? unit.apartment_number : "",
              })
            }
          }}
          required
          requiredIndicator
        />
        <MTextField
          label={t(langKeys.home_address_floor)}
          value={unit?.floor_number}
          onChange={(value) => setUnit({ ...unit, floor_number: value })}
          required
          requiredIndicator
        />
        {unit.space_use_type === "private" && (
          <MTextField
            label={t(langKeys.home_group_building_create_home_apt_number)}
            value={unit?.apartment_number}
            onChange={(value) => setUnit({ ...unit, apartment_number: value })}
            required
            requiredIndicator
          />
        )}
      </Form>
    </MDialog>
  )
}
const EDIT_BUILDING_HOME_FORM_ID = "edit-building-home-form"

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`
