import styled from "styled-components"

import { THome } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import InfoIcon from "src/ui/icons/i-icon.svg"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function HomeGroupAddExistingHomesList({
  homeGroupId,
  homes,
  onAdd,
  onRemove,
  loadingHomeId,
  isLoadingHomes,
  disableAdd,
}: {
  homeGroupId: string
  homes?: THome[]
  onAdd: (homeId: string) => void
  onRemove: (homeId: string) => void
  loadingHomeId?: string
  isLoadingHomes: boolean
  disableAdd?: boolean
}) {
  const rows =
    homes?.map((home) => (
      <>
        <MText variant="subtitle">{home.name}</MText>

        <HomeGroupHomeAction
          home={home}
          homeGroupId={homeGroupId}
          onAdd={onAdd}
          onRemove={onRemove}
          loading={loadingHomeId === home.home_id}
          disableAdd={disableAdd}
        />
      </>
    )) || []

  return (
    <GridTable
      rows={rows}
      templateColumns="2fr auto"
      minWidth={0}
      mobileRows={rows}
      loading={isLoadingHomes}
      disableRowHover
    />
  )
}

function HomeGroupHomeAction({
  home,
  homeGroupId,
  onAdd,
  onRemove,
  loading,
  disableAdd,
}: {
  home: THome
  homeGroupId: string
  onAdd: (homeId: string) => void
  onRemove: (homeId: string) => void
  loading: boolean
  disableAdd?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const isAlreadyInCurrentHomeGroup =
    home.homegroup_id && home.homegroup_id === homeGroupId

  if (isAlreadyInCurrentHomeGroup) {
    return (
      <div>
        <ButtonBox>
          <MButton
            size="small"
            loading={loading}
            onClick={() => onRemove(home.home_id)}
            variant="secondary"
          >
            {t(langKeys.remove)}
          </MButton>
        </ButtonBox>
      </div>
    )
  }

  const isAlreadyInOtherHomeGroup =
    home.homegroup_id && home.homegroup_id !== homeGroupId

  if (isAlreadyInOtherHomeGroup) {
    return (
      <ButtonBox>
        <div>
          {t(langKeys.organizations_homegroup_add_homes_already_added, {})}{" "}
          <MTooltip
            placement="top"
            title={t(langKeys.organizations_home_groups_add_home_tooltip_text)}
          >
            <InfoIcon width={24} />
          </MTooltip>
        </div>
      </ButtonBox>
    )
  }

  return (
    <div>
      <ButtonBox>
        <MButton
          size="small"
          variant="subtle"
          loading={loading}
          onClick={() => onAdd(home.home_id)}
          disabled={disableAdd}
        >
          {t(langKeys.add)}
        </MButton>
      </ButtonBox>
    </div>
  )
}

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`
