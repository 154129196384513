import { List, ListItem } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"

export function HomeGroupAddHomesDropdown({
  buttonText,
  onCreateClick,
  onAddExistingClick,
  onDropdownClick,
}: {
  buttonText: string
  onCreateClick: () => void
  onAddExistingClick: () => void
  onDropdownClick?: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <DropdownButton
      displayValue={buttonText}
      placement="bottom-start"
      variant="primary"
      onClick={onDropdownClick}
    >
      {({ closeMenu }) => (
        <List>
          <ListItem
            onClick={() => {
              onCreateClick()
              closeMenu()
            }}
            button
          >
            {t(
              langKeys.organizations_home_group_homes_empty_state_create_new_action
            )}
          </ListItem>
          <ListItem
            onClick={() => {
              onAddExistingClick()
              closeMenu()
            }}
            button
          >
            {t(
              langKeys.organizations_home_group_homes_empty_state_add_existing_action
            )}
          </ListItem>
        </List>
      )}
    </DropdownButton>
  )
}
