import { useState } from "react"
import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { HomeGroupRolePicker } from "src/components/Organizations/Organization/HomeGroupRolePicker"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { HREF_MINUT_HOME_GROUPS } from "src/constants/hrefs"
import {
  useDeleteHomeGroupMember,
  useFetchHomeGroupMembers,
  usePatchHomeGroupMember,
} from "src/data/homeGroups/queries/homeGroupMemberQueries"
import {
  HomeGroupRole,
  IHomeGroupMember,
  THomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import {
  getAccessLogic,
  getHomeGroupAccessLogic,
} from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownListButton } from "src/ui/Button/DropdownListButton"
import { MButton } from "src/ui/Button/MButton"
import { MDialog } from "src/ui/Dialog/MDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import HomeGroupsEmptyStateIcon from "src/ui/icons/illustrations/people-talking-colored.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const MEMBER_PAGE_SIZE = 15

export function HomeGroupMembers({
  homeGroupId,
  homeGroupRole,
}: {
  homeGroupId: string
  homeGroupRole?: THomeGroup["user_role"]
}) {
  const { navigate } = useRouter()

  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const { org } = useOrganization()
  const { offset, setOffset } = useUrlPager()

  const fetchHomeGroupMembers = useFetchHomeGroupMembers({
    orgId: org.id,
    homeGroupId,
    filter: { limit: MEMBER_PAGE_SIZE, offset },
  })
  const members = fetchHomeGroupMembers.data?.members ?? []

  const patchHomeGroupMember = usePatchHomeGroupMember()
  const deleteHomeGroupMember = useDeleteHomeGroupMember()
  const mutating =
    deleteHomeGroupMember.isLoading || patchHomeGroupMember.isLoading

  const showEmptyState = !members.length && !fetchHomeGroupMembers.isLoading

  const isOrgAdmin = getAccessLogic({ role: org.user_role }).hasAdminAccess

  const [memberToDelete, setMemberToDelete] = useState<IHomeGroupMember>()

  const isHomeGroupAdmin = getHomeGroupAccessLogic({
    role: homeGroupRole,
  }).hasAdminAccess

  const canAddMembers = isOrgAdmin || isHomeGroupAdmin
  const isMemberSelf = memberToDelete?.user_id === user.user_id

  const addMemberOptions = [
    {
      name: t(langKeys.organizations_homegroup_add_member_via_email),
      value: "new-member",
      onClick: () =>
        navigate(Routes.HomeGroupAddMemberEmail.location(homeGroupId)),
    },
    {
      name: t(langKeys.organizations_homegroup_add_existing_member),
      value: "existing-member",
      onClick: () => navigate(Routes.HomeGroupAddMember.location(homeGroupId)),
    },
  ]

  function handleDelete(homeGroupMemberId: string) {
    deleteHomeGroupMember.mutate(
      {
        orgId: org.id,
        homeGroupId,
        homeGroupMemberId,
      },
      {
        onSuccess() {
          setMemberToDelete(undefined)
        },
      }
    )
  }

  function setMemberRole(homeGroupMemberId: string, role: HomeGroupRole) {
    patchHomeGroupMember.mutate({
      orgId: org.id,
      homeGroupId,
      homeGroupMemberId,
      body: { role },
    })
  }

  const rows = members.map((member) => {
    const isSelf = member.user_id === user.user_id
    return (
      <>
        <div>
          <MText variant="subtitle">{member.name}</MText>
          <MText variant="bodyS" color="secondary">
            {member.email}
          </MText>
        </div>

        <div>
          <HomeGroupRolePicker
            inline
            value={member.role}
            disabled={mutating || !isOrgAdmin}
            onChange={(role) => setMemberRole(member.member_id, role)}
          />
        </div>
        <MoreButtonBox>
          <MoreButton
            disabled={mutating || (!isSelf && !isOrgAdmin)}
            onDelete={() => {
              setMemberToDelete(member)
            }}
            deleteLabel={
              isSelf
                ? t(langKeys.organizations_homegroup_leave)
                : t(langKeys.organizations_homegroup_remove)
            }
            showDeleteIcon={isSelf ? false : true}
          />
        </MoreButtonBox>
      </>
    )
  })

  return (
    <div>
      <Title>
        <MText variant="heading2">
          {t(langKeys.organizations_homegroup_access_title)}
        </MText>

        {isOrgAdmin && (
          <DropdownListButton
            displayValue={t(langKeys.organizations_add_member)}
            hidden={!canAddMembers}
            options={addMemberOptions}
          />
        )}
        {!isOrgAdmin && isHomeGroupAdmin && (
          <MButton
            onClick={() =>
              navigate(Routes.HomeGroupAddMemberEmail.location(homeGroupId))
            }
          >
            {t(langKeys.organizations_add_member)}
          </MButton>
        )}
      </Title>
      <Description>
        {t(langKeys.organizations_homegroups_add_member_description)}
      </Description>

      <GridTable
        header={[
          <div key={"member"}>{t(langKeys.member)}</div>,
          <div key={"role-title"}>
            {t(langKeys.organizations_homegroup_role_title)}
          </div>,
          <div key={"misc"}></div>,
        ]}
        rows={rows}
        templateColumns="minmax(max-content, 20ch) max-content auto"
        loading={fetchHomeGroupMembers.isLoading}
        useFallbackResponsiveMode
      />
      <PagerWrapper>
        <Pager
          limit={MEMBER_PAGE_SIZE}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomeGroupMembers.data?.paging.total_count ?? 0}
        />
      </PagerWrapper>

      {showEmptyState && (
        <EmptyState
          icon={<HomeGroupsEmptyStateIcon />}
          title={t(langKeys.organizations_homegroups_empty_state_title)}
          body={
            <StyledLinkText>
              <ExternalLink href={HREF_MINUT_HOME_GROUPS}>
                {t(langKeys.learn_more)}
              </ExternalLink>
            </StyledLinkText>
          }
        />
      )}

      {!!memberToDelete && (
        <MDialog
          open={true}
          onClose={() => {
            setMemberToDelete(undefined)
            deleteHomeGroupMember.reset() // reset potential error states
          }}
          title={
            isMemberSelf
              ? `${t(langKeys.organizations_homegroup_leave)}?`
              : `${t(
                  langKeys.organizations_homegroups_delete_member_confirm_title
                )}?`
          }
          onConfirm={() => {
            if (!memberToDelete) {
              throw Error("Member is null but ideally this should not happen")
            }
            handleDelete(memberToDelete.member_id)
          }}
          loading={deleteHomeGroupMember.isLoading}
          error={
            deleteHomeGroupMember.isError && (
              <div>{t(langKeys.failed_general_error_title)}</div>
            )
          }
          confirmLabel={isMemberSelf ? t(langKeys.leave) : t(langKeys.remove)}
        >
          <MText>
            {isMemberSelf
              ? t(langKeys.organizations_homegroups_self_remove_confirm_body)
              : t(
                  langKeys.organizations_homegroups_member_delete_confirm_body,
                  {
                    member: memberToDelete?.name ?? "",
                  }
                )}
          </MText>
        </MDialog>
      )}
    </div>
  )
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.L};
  margin-bottom: ${spacing.L};
`

const Description = styled.div`
  margin-bottom: ${spacing.L};
`

const StyledLinkText = styled.div`
  margin-top: ${spacing.L};
`

const MoreButtonBox = styled.div`
  place-items: end;
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
