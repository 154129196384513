import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  TSetSearchParamsProp,
  useSearchParams,
} from "src/router/useSearchParams"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import { TTableSort } from "src/ui/GridTable/useTableColumns/useTableColumns"

type THomesFilter = {
  name: string | null
  unit_type: string | null
  floor: string | null
}

export function useHomeGroupBuildingHomesFilter(): {
  filter: THomesFilter
  setFilter: TSetSearchParamsProp<THomesFilter>
  setOffset: (offset: number) => void
  limit: number
  offset: number
  sortValue: TTableSort | null
  setSortValue: (sortValue: TTableSort) => void
  filterApplied: boolean
} {
  const { limit, offset, setOffset } = useUrlPager()
  const { sortValue, setSortValue } = useSortParam()

  const { searchParams, setSearchParamsAll } = useSearchParams({
    keys: [
      {
        key: "name",
        type: "string",
      },
      {
        key: "unit_type",
        type: "string",
      },
      {
        key: "floor",
        type: "string",
      },
    ],
  })

  return {
    filter: searchParams,
    setFilter: (key, value) => {
      // Make sure we reset paging on filter
      setOffset(0)
      setSearchParamsAll({
        [key]: value,
      })
    },
    setOffset: (offset: number) => {
      setOffset(offset)
    },
    limit,
    offset,
    sortValue,
    setSortValue,
    filterApplied: Object.values(searchParams).some((value) => value !== null),
  }
}
