import { HomeGroupBuildingHomes } from "src/components/HomeGroups/HomeGroupBuilding/HomeGroupBuildingHomes"
import { HomeGroupLocationHomes } from "src/components/HomeGroups/HomeGroupLocation/HomeGroupLocationHomes"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"

export function HomeGroupHomes({ homeGroup }: { homeGroup: THomeGroup }) {
  if (homeGroup.type === "building") {
    return <HomeGroupBuildingHomes homeGroup={homeGroup} />
  }

  return <HomeGroupLocationHomes homeGroupId={homeGroup.id} />
}
