import styled from "styled-components"

import { NoHomesInHomeGroup } from "src/components/EmptyState/NoHomesInHomeGroup"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useDeleteHomeGroupHome } from "src/data/homeGroups/queries/homeGroupHomeQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { AddButton } from "src/ui/Button/AddButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupLocationHomes({
  homeGroupId,
}: {
  homeGroupId: string
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { org } = useOrganization()

  const deleteHomeGroupHome = useDeleteHomeGroupHome()

  const { limit, offset, setOffset } = useUrlPager()

  const fetchHomeGroupHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      homegroup_id: homeGroupId,
      limit,
      offset,
    },
  })
  const homes = fetchHomeGroupHomes.data?.homes || []
  const totalCount = fetchHomeGroupHomes.data?.paging.total_count

  if (fetchHomeGroupHomes.isError) {
    return (
      <MBanner type="error" title={t(langKeys.failed_something_went_wrong)} />
    )
  }
  if (fetchHomeGroupHomes.isLoading) {
    return <MSkeleton variant="rect" width="100%" height={200} />
  }

  const isOrgAdmin = getAccessLogic({ role: org.user_role }).hasAdminAccess

  function onDelete(home_id: string) {
    return deleteHomeGroupHome.mutate({
      orgId: org.id,
      homeGroupId,
      home_id,
    })
  }

  const rows: JSX.Element[] = homes.map((home) => {
    return (
      <>
        <MText variant="subtitle" key={home.home_id}>
          <InternalLink to={Routes.Home.location(home.home_id)}>
            {home.name}
          </InternalLink>
        </MText>

        <ButtonBox>
          {isOrgAdmin && <MoreButton onDelete={() => onDelete(home.home_id)} />}
        </ButtonBox>
      </>
    )
  })

  if (homes.length === 0) {
    return (
      <NoHomesInHomeGroup
        type="area"
        onClick={() => navigate(Routes.HomeGroupAddHomes.location(homeGroupId))}
      />
    )
  }

  return (
    <HomeGroupHomesBox>
      <Top>
        <MText variant="heading2">{t(langKeys.home_plural)}</MText>
        {isOrgAdmin && (
          <AddButton
            onClick={() =>
              navigate(Routes.HomeGroupAddHomes.location(homeGroupId))
            }
          >
            {t(langKeys.add_home)}
          </AddButton>
        )}
      </Top>

      <GridTable
        header={[
          <div key={"home"}>{t(langKeys.home)}</div>,
          <div key={"misc"}></div>,
        ]}
        rows={rows}
        templateColumns="1fr auto"
        useFallbackResponsiveMode
      />
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={totalCount}
        />
      </PagerWrapper>
    </HomeGroupHomesBox>
  )
}

const HomeGroupHomesBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
